<template>
    <div>
        <!-- 托管服务-托管账单-->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="contetn-wrappr">
                <div class="filter_disFlex">
                    <expand-filter
                        :formData="formData"
                        marginTop="10px"
                        marginBottom="0px"
                        borderRadius="0px 4px 4px 4px"
                        paddingTop="0px"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        @changeSel="changeSel"
                        @datePickerBlur="datePickerBlur"
                    ></expand-filter>
                    <div class="filter_Btn">
                        <el-button @click="importExcle()">导入</el-button>
                        <el-button
                            :loading="exportLoading"
                            v-hasPermi="['depositBill:export']"
                            @click="handleExport()"
                            >导出</el-button
                        >
                    </div>
                </div>
                <div class="disFel">
                    <div class="dis_left">
                        <div class="mr30">
                            应缴总金额：{{ totalPayableAmount }}元
                        </div>
                        <div class="mr30">
                            已缴总金额：{{ totalPayAmount }}元
                        </div>
                        <div>未缴总金额：{{ totalUnPayableAmount }}元</div>
                    </div>
                    <div
                        class="dis_right"
                        @click="openPrice"
                    >
                        缴费单价：{{ price.unitPrice || "-" }}元/天
                    </div>
                </div>
                <div class="table-paging">
                    <table-data
                        :config="table_config"
                        :tableData="table_data"
                        v-loading="loadingTable"
                        backgroundHeader="#FAFBFC"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        ref="table"
                        id="table"
                        :to-top="false"
                    >
                        <template #title="{data}">
                            <el-button
                                type="text"
                                @click="handleDetails(data)"
                                :disabled="
                                    !hasPermission(['depositBill:billDetail'])
                                "
                                ><span
                                    style="text-align:center;"
                                v-if="data.sourceType == '2'"
                                    >[导入]</span
                                >
                                <span class="an-omission">{{ data.title }}</span>
                                </el-button
                            >
                        </template>
                        <template #depositClasses="{data}">
                            <span
                                style="color: #3C7FFF; cursor: pointer"
                                @click="handleClass(data)"
                                >班级详情</span
                            >
                        </template>
                        <template v-slot:operation="slotData">
                            <xk-button
                                v-if="slotData.data.showBtn"
                                v-hasPermi="['depositBill:singlePushBill']"
                                size="mini"
                                @click="handelPush(slotData.data)"
                                :style="{
                                    color: '#3C7FFF',
                                    border: 'none',
                                    background: 'none',
                                }"
                                >推送账单</xk-button
                            >
                            <xk-button
                                v-if="slotData.data.showDeleteBtn"
                                size="mini"
                                style="
                                    color: #f26231;
                                    border: none;
                                    background: none;
                                "
                                @click="handelDetele(slotData.data)"
                                >删除</xk-button
                            >
                        </template>
                    </table-data>
                    <pagination
                        v-hasPermi="['depositBill:list']"
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList()"
                    />
                </div>
                <!-- 导入 -->
                <DialogWrapper
                    :dialogObj="importFamilyObj"
                    @handleClose="handleImportFamilyClose"
                >
                    <ImportState
                        ref="importFamilyRef"
                        :import-form="importFamilyRuleForm"
                        @download="handleImportFamilyDownload"
                        @next="handleImportFamilyNext"
                        @save="handleImportFamilySave"
                        @close="handleImportFamilyClose"
                    />
                </DialogWrapper>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
        <dialog-wrapper
            :dialogObj="priceObj"
            @handleClose="priceClose"
        >
            <el-form
                :model="priceFormData"
                label-width="80px"
                :rules="priceFormRules"
                ref="setPriceRef"
            >
                <el-form-item
                    label="缴费单价"
                    prop="price"
                >
                    <el-input
                        v-model="priceFormData.price"
                        @input="handleprice($event)"
                        placeholder="请输入单价"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="m5">设置托管服务每天的单价</div>
            <el-checkbox v-model="priceFormData.checked"
                >更新所有未缴费账单金额</el-checkbox
            >
            <div class="m5">选中，会更改所有未缴费状态的学生账单信息</div>
            <div class="m5">未选中，只会更改所有未发送状态的学生账单信息</div>
            <div style="text-align: center">
                <el-button @click="priceClose">取消</el-button>
                <el-button
                    @click="handelSetPrice"
                    type="primary"
                    >保存</el-button
                >
            </div>
        </dialog-wrapper>
        <!-- 班级详情 -->
        <dialog-wrapper
            :dialogObj="classObj"
            @handleClose="classClose"
        >
            <el-menu
                class="el-menu-vertical-demo"
                :default-openeds="openeds"
            >
                <el-submenu
                    v-for="(item, index) in classList"
                    :index="index.toString()"
                    :key="index"
                >
                    <template slot="title">
                        <span>{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item
                            v-for="(i, idx) in item.child"
                            :key="idx"
                            :index="idx.toString()"
                            >{{ i.name }}</el-menu-item
                        >
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </dialog-wrapper>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    MTreeSelect,
    Pagination,
    DialogWrapper,
    debounce,
} from "common-local";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import TableData from "../Sub/TableData.vue";
import {CustodialServicesModel} from "@/models/CustodialServices.js";
import {mapState} from "vuex";
import ImportState from "./ImportState/index.vue";
import {downloadFile} from "@/libs/utils";
import {hasPermission} from "@/libs/utils";
import RecordsList from '@/libs/pageListOptimal.js';
import onResize from "@/mixins/onResize"
export default {
    name: "TrusteeshipBillView",
    mixins: [onResize],
    components: {
        FilterData,
        Loading,
        TableData,
        Pagination,
        MTreeSelect,
        ExpandFilter,
        DialogWrapper,
        ImportState,
    },
    props: {
        detailData: {
            type: Object,
            default: "",
        },
    },
    data() {
        return {
            hasPermission,
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            exportLoading: false,
            // 班级多选
            value: [],
            selectTree: [],
            multipleChoiceClasses: [],
            menuList: [],
            oneDimensionalArrays: [],
            defaultProps: {
                children: "child",
                label: "name",
            },
            openeds: [],
            listQuery: {
                pageRow: 20,
                pageNum: 1,
                title: "",
                gradeId: "",
                classId: "",
                startTime: "",
                endTime: "",
                jobScope: ["2", "3"],
                billStatus: "",
                payStatus: "",
                sourceType: "",
                isCreateOrder: 1,
            },
            // 统计数据
            totalPayableAmount: "", // 应缴金额
            totalPayAmount: "", // 已缴金额
            totalUnPayableAmount: "", // 未缴金额
            // 单价
            price: {
                unitPrice: "",
                code: "",
                id: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入项目名称",
                        key: "title",
                    },
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "datetimerange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "请选择开始时间",
                        endPlaceholder: "请选择结束时间",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "账单状态",
                        key: "billStatus",
                        list: [
                            {
                                value: "1",
                                label: "未发送",
                            },
                            {
                                value: "2",
                                label: "已发送",
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "缴费状态",
                        key: "payStatus",
                        list: [
                            {
                                value: "1",
                                label: "待缴费",
                            },
                            {
                                value: "2",
                                label: "缴费中",
                            },
                            {
                                value: "3",
                                label: "已缴费",
                            },
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "账单类型",
                        key: "sourceType",
                        list: [
                            {
                                value: "1",
                                label: "自动生成",
                            },
                            {
                                value: "2",
                                label: "导入",
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["depositBill:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["depositBill:list"],
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        type: "slot",
                        slotName: "title",
                        fixed: false,
                        align: 'center',
                        labelWidth: '200px',
                    },
                    {
                        label: "托管日期",
                        prop: "date",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "账单状态",
                        prop: "billStatus",
                        type: "function",
                        fixed: false,
                        align: 'center',
                        callBack: (row, prop) => {
                            switch (row.billStatus) {
                                case "1":
                                    return "未发送";
                                case "2":
                                    return "已发送";
                            }
                        },
                    },
                    {
                        label: "缴费状态",
                        prop: "payStatus",
                        type: "function",
                        fixed: false,
                        align: 'center',
                        callBack: (row, prop) => {
                            switch (row.payStatus) {
                                case "1":
                                    return "待缴费";
                                case "2":
                                    return "缴费中";
                                case "3":
                                    return "已缴费";
                            }
                        },
                    },
                    {
                        label: "班级",
                        type: "slot",
                        slotName: "depositClasses",
                        fixed: false,
                        align: 'center',
                        className: 'text-spacing-reduction'

                    },
                    {
                        label: "学生数量",
                        prop: "studentNum",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "托管总天数",
                        prop: "depositDay",
                        fixed: false,
                        align: 'center',
                    },
                    {
                        label: "应缴金额",
                        prop: "payableAmount",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "已缴金额",
                        prop: "payAmount",
                        fixed: false,
                        align: 'center'
                    },
                    {
                        label: "首次发送账单时间",
                        prop: "newFirstSendTime",
                        align: 'center'
                    },
                    {
                        label: "创建人",
                        prop: "createName",
                        align: 'center'
                    },
                    {
                        label: "创建时间",
                        prop: "newCreateTime",
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            table_data: [],
            total: 0,
            showBtn: true,
            showDeleteBtn: false,
            priceObj: {
                title: "",
                dialogVisible: false,
                width: "350px",
            },
            priceFormData: {
                price: "",
                checked: false, // 勾选状态
            },
            priceFormRules: {
                price: [
                    {
                        max: 5,
                        required: true,
                        message: "请输入单价",
                        trigger: "blur",
                    },
                ],
            },
            classObj: {
                title: "班级详情",
                dialogVisible: false,
                width: "350px",
            },
            addClasses: {
                schoolId: "",
                gradeLevel: "",
            },

            classOptions: [],
            classList: [],
            flag: true,
            detailState: true,
            detailAuth: ["deposit:stu"],
            //导入
            importFamilyObj: {
                title: "导入",
                dialogVisible: false,
                width: "900px",
            },
            importFamilyRuleForm: {
                schoolId: this.$store.state.schoolId,
                title: "",
                depositStartDate: "",
                depositEndDate: "",
                active: 0,
                file: "",
                handleType: "1", // 2继续执行  1终止导入
            },
        };
    },
    created() {
        this.init();
    },
    activated(){
        this.setTableScrollHeight();
    },
    mounted() {
        this.selectTree = this.flatten(this.multipleChoiceClasses);
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
            schoolId: (state) => state.schoolId,
            gradeLevel: (state) => state.gradeLevel,
        }),
    },
    methods: {
        /**
         * @Description: 设置单价规则
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 18:53:30
         * @param {*} e
         */
        handleprice(e) {
            let value = e.replace(/[^\d.]/g, ""); // 只能输入.和数字
            value = value.replace(/^\./g, ""); //第一个字符不能是.
            value = value.replace(/\.{2,}/g, "."); // 不能连续输入.
            value = value.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            value = value.replace(/(\d{5})\d*/, "$1"); // 最多保留5位整数
            // value = value.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            value = value.replace(/(\.\d{2})\d*/, "$1"); // 最多保留2位小数
            this.priceFormData.price = value;
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name)
                },50)
            }
        },
        /**
         * @Description: 初始化数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:46:38
         */
        init() {
            this.initAssignment();
            this.initPageData();
        },
        initAssignment() {
            this.addClasses.schoolId = this.schoolId;
            this.addClasses.gradeLevel = this.gradeLevel;

            this.detailState = this.permissions.some((permission) => {
                return this.detailAuth.includes(permission);
            });
            this.setPageInfo();
        },
        async initPageData() {
            await this.getGradeList();
            await this.getAddClassesList();
            await this.getList();
            await this.getSummary();
            await this.getUnitPrice();
        },
        async getGradeList() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getYearDropDownList({
                    schoolId: this.$store.state.schoolId,
                    jobScope: ["2", "3"],
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        let gradeList = [],
                            setGrade = this.formData.data.find((item) => {
                                return item.key == "gradeId";
                            });

                        gradeList = res.data.data.map((item) => {
                            return {
                                label: item.name,
                                value: item.id,
                            };
                        });
                        setGrade.list = gradeList;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },

        async getClassList(gradeId) {
            const classIndex = this.formData.data.findIndex(
                (i) => i.key === "classId",
            );
            this.formData.data[classIndex].value = "";
            this.formData.data[classIndex].list = [];
            this.listQuery.classId = "";
            if (gradeId) {
                const custodialServicesModel = new CustodialServicesModel();
                custodialServicesModel
                    .getClassDropDownList({
                        schoolId: this.$store.state.schoolId,
                        gradeId: gradeId,
                        jobScope: ["2", "3"],
                    })
                    .then((res) => {
                        if (res.data.code === "200") {
                            // console.log(res, "1111");
                            this.formData.data.forEach((item) => {
                                if (item.key === "classId") {
                                    let classList = res.data.data.map((i) => ({
                                        label: i.name,
                                        value: i.id,
                                    }));
                                    item.list = classList;
                                }
                            });
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
            }
        },
        /**
         * @Description: 查询账单汇总
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-25 14:15:00
         */
        async getSummary() {
            await this._fet("/school/schoolDeposit/getSummary", this.listQuery)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.totalPayableAmount =
                            res.data.data.totalPayableAmount;
                        this.totalPayAmount = res.data.data.totalPayAmount;
                        this.totalUnPayableAmount =
                            res.data.data.totalUnPayableAmount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {});
        },
        /**
         * @Description: 查询缴费单价
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-24 18:27:05
         */
        async getUnitPrice() {
            await this._fet("/school/schoolDeposit/getDepositUnitPrice", {
                schoolId: this.schoolId,
            })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.price.unitPrice = res.data.data.name;
                        this.price.code = res.data.data.code;
                        this.price.id = res.data.data.id;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {});
        },
        /**
         * @Description: 设置缴费单价弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 17:02:57
         */
        openPrice: debounce(function () {
            if (this.price.unitPrice === "") {
                this.priceObj.title = "添加单价";
            } else {
                this.priceObj.title = "编辑单价";
            }
            this.priceObj.dialogVisible = true;
        }, 200),
        // 关闭设置单价弹窗
        priceClose() {
            this.priceObj.dialogVisible = false;
            this.$refs.setPriceRef.resetFields();
            this.priceFormData.price = "";
        },
        /**
         * @Description: 设置单价保存
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 18:46:07
         */
        handelSetPrice: debounce(function () {
            this.$refs.setPriceRef.validate((valid) => {
                if (valid) {
                    if (this.priceFormData.checked) {
                        let checkForm = {
                            schoolId: this.schoolId,
                            code: 1,
                            id: this.price.id,
                            name: this.priceFormData.price,
                        };
                        // 勾选了
                        this.$confirm(
                            "所有未缴费的账单会按照最新单价重新计算应缴金额，是否确认保存吗？",
                            "提示",
                            {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消",
                                type: "warning",
                            },
                        )
                            .then(() => {
                                this._fet(
                                    "/school/schoolDeposit/saveDepositUnitPrice",
                                    checkForm,
                                ).then((res) => {
                                    if (res.data.code == "200") {
                                        this.$message.success("保存成功");
                                        this.getUnitPrice();
                                        this.getList();
                                        this.getSummary();
                                    } else if (res.data && res.data.msg) {
                                        this.$message.error(res.data.msg);
                                    }
                                    this.priceClose();
                                });
                            })
                            .catch(() => {
                                this.priceObj.dialogVisible = false;
                            });
                    } else {
                        let checkForm1 = {
                            schoolId: this.schoolId,
                            code: 2,
                            id: this.price.id,
                            name: this.priceFormData.price,
                        };
                        // 未勾选
                        this.$confirm(
                            "所有未发送的账单会按照最新单价重新计算应缴金额，是否确认保存吗？",
                            "提示",
                            {
                                confirmButtonText: "确定",
                                cancelButtonText: "取消",
                                type: "warning",
                            },
                        )
                            .then(() => {
                                this._fet(
                                    "/school/schoolDeposit/saveDepositUnitPrice",
                                    checkForm1,
                                ).then((res) => {
                                    if (res.data.code == "200") {
                                        this.$message.success("保存成功");
                                        this.getUnitPrice();
                                        this.getList();
                                        this.getSummary();
                                    } else if (res.data && res.data.msg) {
                                        this.$message.error(res.data.msg);
                                    }
                                    this.priceClose();
                                });
                            })
                            .catch(() => {
                                this.priceObj.dialogVisible = false;
                            });
                    }
                } else {
                    return false;
                }
            });
        }, 200),
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:46:25
         * @param {*} isPaging
         */
        getList(isPaging) {
            this.loadingTable = true;
            this.$forceUpdate();
            if (isPaging) {
                this.listQuery.pageNum = 1;
            }
            this._fet("/school/schoolDeposit/list", this.listQuery).then(
                (res) => {
                    if (res.data.code == "200") {
                        res.data.data.list.forEach((i) => {
                            i.showBtn = true;
                            i.showDeleteBtn = false;
                        });
                        const list = res.data.data.list;
                        list.forEach((item) => {
                            let time =
                                item.depositStartDate.split(" ")[0] +
                                " - " +
                                item.depositEndDate.split(" ")[0];
                            this.$set(item, "date", time);
                            if (item.sourceType == "1") {
                                this.$set(item, "createName", "自动生成");
                            }
                            if (item.billStatus == "3") {
                                item.showBtn = false;
                            }
                            if (item.payableAmount == 0) {
                                item.showBtn = false;
                            }
                            if (item.payStatus == "3") {
                                item.showBtn = false;
                            }
                            if (
                                item.billStatus == "1" &&
                                item.sourceType == "2"
                            ) {
                                item.showDeleteBtn = true;
                            }

                            if (item.firstSendTime) {
                                this.$set(item, 'newFirstSendTime', item.firstSendTime.substring(0, item.firstSendTime.length - 3))
                            } else {
                                this.$set(item, 'newFirstSendTime', '-')
                            }

                            if (item.createTime) {
                                this.$set(item, 'newCreateTime', item.createTime.substring(0, item.createTime.length - 3))
                            } else {
                                this.$set(item, 'newCreateTime', '-')
                            }
                        });
                        setTimeout(() => {
                            this.filterTreeData(list);
                        }, 500);

                        this.table_data = list;
                        this.total = res.data.data.totalCount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                },
            ).catch(err => {
                this.loadingTable = false;
            })
        },
        async getAddClassesList() {
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel
                .getAddClassesList(this.addClasses)
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;

                        this.menuList = JSON.parse(JSON.stringify(data));
                        // console.log(this.menuList,'所有年级、班级信息');
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 过滤属性数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:46:11
         * @param {*} list
         */
        filterTreeData(list) {
            list.forEach((item) => {
                let menuList = JSON.parse(JSON.stringify(this.menuList));
                let classIds, gradeIds;
                classIds = item.depositClasses.map((subItem) => {
                    return subItem.classId;
                });
                gradeIds = item.depositClasses.map((subItem) => {
                    return subItem.gradeId;
                });
                // console.log(menuList, "menuList", );
                let ultimateArray = [...new Set(gradeIds), ...classIds];
                this.filterMenu(menuList, ultimateArray);
                this.delMenu(menuList, "show");
                this.$set(item, "menuList", menuList);
            });
        },
        /**
         * @Description: 树形数据添加标识
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:46:00
         * @param {*} menuList
         * @param {*} menuCode
         */
        filterMenu(menuList, menuCode) {
            menuList.forEach((item) => {
                menuCode.forEach((subItem) => {
                    if (item.id == subItem) {
                        this.$set(item, "show", true);
                    }

                    if (item.child && item.child.length > 0) {
                        this.filterMenu(item.child, menuCode);
                    }
                });
            });
        },
        /**
         * @Description: 删除多余树形数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:45:49
         * @param {*} menuList
         * @param {*} pointer
         */
        delMenu(menuList, pointer) {
            for (var i = menuList.length - 1; i >= 0; i--) {
                let item = menuList[i];
                if (!item.hasOwnProperty(pointer)) {
                    menuList.splice(i, 1);
                }

                if (item.child && item.child.length > 0) {
                    this.delMenu(item.child, pointer);
                }
            }
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:45:13
         * @param {*} arr
         */
        flatten(arr) {
            return [].concat(
                ...arr.map((item) => {
                    if (item.children) {
                        let arr = [].concat(
                            item,
                            ...this.flatten(item.children),
                        );
                        delete item.children;
                        return arr;
                    }
                    return [].concat(item);
                }),
            );
        },
        /**
         * @Description: 修改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:43:58
         * @param {*} data
         */
        changeSel(data) {
            switch (data.key) {
                case "gradeId":
                    this.getClassList(data.value);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 日期选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:43:42
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value ? dateArr.value : ["", ""];
            this.listQuery.startTime = startTime;
            this.listQuery.endTime = endTime;
        },
        /**
         * @Description: 列表 - 班级详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:43:09
         * @param {*} data
         */
        handleClass(data) {
            // console.log('data33333333333333333',data);
            this.openeds = [];
            data.menuList.forEach((item, index) => {
                this.openeds.push(index.toString());
            });

            this.classList = data.menuList;
            // console.log(this.classList, "this.classList", data.menuList, data);
            this.classObj.dialogVisible = true;
        },
        classClose() {
            this.openeds = [];
            this.classList = [];
            this.classObj.dialogVisible = false;
        },

        /**
         * @Description: 详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:42:55
         * @param {*} data
         */
        handleDetails: debounce(function (data) {
            this.savePageInfo();
            this.$emit("toDetail", {
                data: data,
            });
        }, 200),
        /**
         * @Description: 查询 / 重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:42:38
         * @param {*} type
         */
        clickBtn: debounce(function (type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    this.getSummary();
                    break;
                case "reset": //重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    this.listQuery = {
                        pageRow: 10,
                        pageNum: 1,
                        gradeId: "",
                        classId: "",
                        startTime: "",
                        endTime: "",
                        jobScope: ["2", "3"],
                        title: "",
                        billStatus: "",
                        payStatus: "",
                        sourceType: "",
                        isCreateOrder: 1,
                    };
                    this.getList();
                    this.getSummary();
                    break;

                default:
                    break;
            }
        }, 200),

        /**
         * @Description: 推送账单
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:49:07
         */
        handelPush: debounce(function (data) {
            this.$confirm(
                "所有未缴费状态的学生家长都会收到推送账单，是否确认发送？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    this._fet("/school/schoolDeposit/sendPushBillMessage", {
                        id: data.id,
                    }).then((res) => {
                        if (res.data.code == "200") {
                            this.$message.success("发送成功");
                            // 当前数据发送状态变成已发送
                            // this.$set(data, "billStatus", "2");
                            this.getList();
                            this.getSummary();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        }, 200),

        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:40:59
         * @param {*} data
         */
        handelDetele: debounce(function (data) {
            this.$confirm(
                "学生账单也会同步删除，不可撤销，是否确认删除？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    this._fet("/school/schoolDeposit/deleteBill", {
                        id: data.id,
                    }).then((res) => {
                        if (res.data.code == "200") {
                            this.$message.success("操作成功");
                            this.getList();
                            this.getSummary();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        }, 200),

        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 16:53:07
         */
        handleExport: debounce(function () {
            this.exportLoading = true;
            let form = {
                title: this.listQuery.title,
                gradeId: this.listQuery.gradeId,
                classId: this.listQuery.classId,
                startTime: this.listQuery.startTime,
                endTime: this.listQuery.endTime,
                jobScope: this.listQuery.jobScope,
                billStatus: this.listQuery.billStatus,
                payStatus: this.listQuery.payStatus,
                sourceType: this.listQuery.sourceType,
                schoolId: this.listQuery.schoolId,
                isCreateOrder: 1,
            };

            downloadFile(
                {
                    url: "/school/schoolDeposit/export",
                    form,
                },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        }, 200),
        // 导入
        importExcle: debounce(function () {
            this.importFamilyObj.dialogVisible = true;
        }, 200),
        /**
         * @Description: 导入下载模版
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:27:21
         */
        handleImportFamilyDownload: debounce(function () {
            this._get("/school/schoolDeposit/template").then((res) => {
                if (res.data.code === "200") {
                    let fileName = res.data.data.split("/")[3];
                    downloadFile(
                        {url: res.data.data, method: "get", fileName: fileName},
                        () => {},
                        () => {},
                    );
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        }, 200),
        /**
         * @Description: 导入 - 下一步
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:27:57
         */
        async handleImportFamilyNext() {
            if (
                this.importFamilyRuleForm.dataArr &&
                this.importFamilyRuleForm.dataArr.length > 0
            ) {
                this.importFamilyRuleForm.depositStartDate =
                    this.importFamilyRuleForm.dataArr[0];
                this.importFamilyRuleForm.depositEndDate =
                    this.importFamilyRuleForm.dataArr[1];
            }
            if (this.importFamilyRuleForm.title === "") {
                return this.$message.warning("请输入项目名称！");
            }
            if (!this.importFamilyRuleForm.dataArr) {
                return this.$message.warning("请选择托管日期！");
            }
            if (this.importFamilyRuleForm.file === "") {
                return this.$message.warning("请上传文件");
            }
            this.importFamilyRuleForm.active++;
        },
        /**
         * @Description: 导入 - 保存
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:28:11
         */
        handleImportFamilySave() {
            const file = this.importFamilyRuleForm.file;
            let formData = new FormData();
            // console.log(this.importFamilyRuleForm, "数据");
            formData.append("file", file.raw);
            formData.append("schoolId", this.$store.state.schoolId);
            formData.append("handleType", this.importFamilyRuleForm.handleType);
            formData.append("title", this.importFamilyRuleForm.title);
            formData.append(
                "depositStartDate",
                this.importFamilyRuleForm.depositStartDate,
            );
            formData.append(
                "depositEndDate",
                this.importFamilyRuleForm.depositEndDate,
            );
            // console.log(formData, "formData");
            const custodialServicesModel = new CustodialServicesModel();
            custodialServicesModel.importDepositUpload(formData).then((res) => {
                if (res.data.code === "200") {
                    this.importFamilyRuleForm.active++;
                    // console.log(res.data.data, "导入返回数据");
                    this.importFamilyRuleForm.successData = res.data.data.count;
                    // if (!this.importFamilyRuleForm.successData.rightCount) {
                    //     this.importFamilyRuleForm.successData.rightCount = 0
                    // }
                    this.$message.success("导入成功");
                    this.getList();
                    this.getSummary();
                    if (res.data.data.errorId) {
                        this.downloadImportFamilyErrorList(res.data);
                    }
                    this.importFamilyRuleForm.title = "";
                    this.importFamilyRuleForm.dataArr = [];
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else {
                    this.getList();
                    this.getSummary();
                    this.downloadImportFamilyErrorList(res.data);
                }
            });
        },
        downloadImportFamilyErrorList(data) {
            // console.log(data, "错误信息");
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: `/school/schoolDeposit/errorExport`,
                            method: "post",
                            fileName: "导入错误信息表.xlsx",
                            form: {paramData: data.info.paramData},
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        /**
         * @Description: 关闭导入弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-23 15:28:32
         */
        handleImportFamilyClose() {
            this.importFamilyObj.dialogVisible = false;
            this.importFamilyRuleForm.active = 0;
            this.importFamilyRuleForm.schoolId = "";
            this.importFamilyRuleForm.file = "";
            this.importFamilyRuleForm.title = "";
            this.importFamilyRuleForm.dataArr = [];
            this.importFamilyRuleForm.depositStartDate = "";
            this.importFamilyRuleForm.depositEndDate = "";
        },
    },
};
</script>

<style lang="scss" scoped>
.contetn-wrappr{
    padding-right: 10px;
}
.an-omission {
    max-width: 120px;
    // text-align: left;
    display: inline-block;
    vertical-align: bottom;
    overflow:hidden;
    word-break:keep-all;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.meetings-my-list {
    .content-wrapper .el-date-editor {
        width: 340px !important;
    }

    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }
}
.filter_disFlex {
    margin: 10px 0px;
    display: flex;
    border-radius: 4px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
}

.filter_Btn {
    flex-shrink: 0;
    padding-right: 20px;
    display: flex;
    align-items: center;
    .mr20_btn {
        margin-right: 20px;
    }
}
.disFel {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    font-size: 14px;
    .dis_left {
        font-weight: 700;
        display: flex;
        .mr30 {
            margin-right: 30px;
        }
    }
    .dis_right {
        cursor: pointer;
        color: #3C7FFF;
    }
}
/deep/ .el-select {
    width: 100% !important;
}
/deep/ .el-menu-vertical-demo {
    border: solid 1px #e6e6e6 !important;
}
/deep/ .el-submenu__title {
    height: 30px !important;
    line-height: 30px;
    font-size: 14px;
    color: #303133;
    padding: 0;
}
/deep/.el-menu-item-group {
    background: #f7f7f7;
}
/deep/.el-menu-item-group__title {
    padding: 0;
}
/deep/ .el-submenu .el-menu-item {
    height: 20px !important;
    line-height: 20px;
    font-size: 13px;
    color: #303133;
    padding: 0;
}
// /deep/ .el-form-item__label {
//     text-align: left;
// }
.open-style {
    min-height: 200px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
}
.stafftree {
    /deep/ .el-tree-node {
        .el-checkbox__input.is-disabled {
            display: none;
        }
    }
}
.m5 {
    margin: 10px 0;
    font-size: 12px;
    color: #6d7073;
}
::v-deep .el-button.is-disabled.el-button--text {
    font-size: 14px;
    color: #6d7073;
}
::v-deep .el-button.el-button--text {
    font-size: 14px;
}
</style>
