<template>
    <!-- 托管服务-托管账单 -->
    <keep-alive include="TrusteeshipBillView">
        <component
            v-bind:is="currentTabComponent"
            :detail-data="detailData"
            :list-data="listData"
            @toList="toList"
            @toDetail="toDetail"
            @setPageInitTabs="setPageInitTabs"
        ></component>
    </keep-alive>
</template>
<script>
import TrusteeshipBillList from './TrusteeshipBill/TrusteeshipBillView.vue';
import TrusteeshipBillInfo from './TrusteeshipBill/TrusteeshipBillInfo.vue';

export default {
    name: "TrusteeshipBillScrollWrapper",
    components: {
        TrusteeshipBillList,
        TrusteeshipBillInfo
    },
    data() {
        return {
            // 切换组件
            compList: ['TrusteeshipBillList', 'TrusteeshipBillInfo'],
            idx: 0,
            listData: {},
            detailData: {}
        }
    },
    computed: {
        currentTabComponent() {
            return this.compList[this.idx];
        }
    },
    methods: {
        toList(listData) {
            this.idx = 0
            this.listData = listData
        },
        toDetail(detailData) {
            this.idx = 1
            this.detailData = detailData
        },
        setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0;
        }
    }
}
</script>
